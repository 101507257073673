// Importaciones de React y Hooks
import React from 'react';
import { useTheme, useMediaQuery as muiUseMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

// Importaciones de Material-UI
import Grid from '@mui/material/Grid';
import { Box, Card } from '@mui/material';
import { keyframes } from '@mui/material/styles';

// Importaciones de Material Kit 2 PRO React
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Importar estilos de SwiperJS si es necesario
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';

// Importaciones de Imágenes
import bootcampIcon from 'assets/images/bootcamp-icon.png';
import incubatorIcon from 'assets/images/incubator-icon.png';
import academy from 'assets/images/academy-banner.png';
import ventures from 'assets/images/ventures-banner.png';
import kImage from 'assets/images/banners/k-image.png';
import oImage from 'assets/images/banners/o-image.png';
import iImage from 'assets/images/banners/i-image.png';
import nImage from 'assets/images/banners/n-image.png';
import fondoVision from 'assets/images/FondoVision.png'; // Asegúrate de importar fondoVision

// Datos para los bloques
const dataBlocks = [
  {
    name: 'Academy',
    description: 'Incubation, acceleration and growth programs for impact-driven startups.',
    key: 'academy',
    route: '/academy',
    image: kImage,
  },
  {
    name: 'VC',
    description:
      'Innovative investment opportunities paired with specialized tutoring and guidance for angel investors.',
    key: 'ventures',
    route: '/ventures',
    image: oImage,
  },
  {
    name: 'Build',
    description: 'We build our own products and services.',
    key: 'build',
    route: '/build',
    image: iImage,
  },
  {
    name: 'Technology',
    description: 'We provide technology solutions to companies.',
    key: 'tech',
    route: '/tech',
    image: nImage,
  },
];

// Definir la animación de flotación usando keyframes

function AboutUs() {
  const theme = useTheme();
  const isXs = muiUseMediaQuery(theme.breakpoints.down('sm')); // Uso del hook de MUI

  return (
    <section id="about-us">
      <Box
        display="flex"
        position="relative"
        sx={{
          width: '100%',
        }}
      >
        <Grid
          container={!isXs}
          spacing={4}
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            padding: { xs: 2, md: 5 },
          }}
        >
          {/* Contenedor de Texto */}
          <Grid item xs={12} md={12}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <MKTypography variant="h1" color="text" sx={{ mb: 2 }}>
                <span
                  style={{
                    fontWeight: 'bold',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                  }}
                >
                  What
                </span>{' '}
                we do
              </MKTypography>
            </Box>
          </Grid>

          {/* Renderizar los Bloques de Datos */}
          {dataBlocks.map((item) => (
            <Grid item xs={12} sm={6} md={3} key={item.key}>
              <Card
                component={Link}
                to={item.route}
                sx={{
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '25px',
                  height: '326px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'space-between',
                  color: '#fff',
                  padding: '20px 30px',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  boxShadow: '0 8px 25px rgba(0, 0, 0, 0.3)', // Sombra más prominente
                  transition: 'transform 0.5s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',

                    borderRadius: '25px',
                    zIndex: 1,
                  },
                  '@media (max-width: 600px)': {
                    m: '20px',
                  },
                }}
              >
                <MKTypography
                  variant="h3"
                  sx={{
                    color: item.name === 'Technology' ? '#fff' : '#000',
                    fontWeight: 'bold',
                    mb: 1,
                  }}
                >
                  {item.name}
                </MKTypography>
                <MKTypography
                  variant="body2"
                  sx={{
                    color: item.name === 'Technology' ? '#fff' : '#000',
                  }}
                >
                  {item.description}
                </MKTypography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </section>
  );
}

export default AboutUs;
