// @mui material components
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import CustomContainer from 'DS/components/Container';

// Material Kit 2 PRO React components
import MKBox from 'DS/components/MKBox';
import MKButton from 'DS/components/MKButton';
import MKTypography from 'DS/components/MKTypography';

// Images

// Acceleration
import enlite from 'assets/images/logos/Enlite (2).png';
import busencu from 'assets/images/logos/busencu-logo.svg';
import dillo from 'assets/images/logos/dillo-logo.png';
import DrGea from 'assets/images/logos/dr-gea.png';
import duo0 from 'assets/images/logos/duo0-logo.png';
import Vgo from 'assets/images/logos/logo-V-Go.png';
import Aconcagua from 'assets/images/logos/logo-aconcagua.png';
import akademi from 'assets/images/logos/logo-akademi.png';
import Dendro from 'assets/images/logos/logo-dendro.png';
import fincrick from 'assets/images/logos/logo-fincrick.png';
import goldu from 'assets/images/logos/logo-glodu.png';
import Maia from 'assets/images/logos/logo-maia.svg';
import mentalgram from 'assets/images/logos/logo-mentalgram.svg';
import Nippy from 'assets/images/logos/logo-nippy.svg';
import NoPausa from 'assets/images/logos/logo-no-pausa.png';
import Qxm from 'assets/images/logos/logo-qxm.png';
import rezet from 'assets/images/logos/logo-rezet.png';
import SomosFit from 'assets/images/logos/logo-somosfit.png';
import tributo from 'assets/images/logos/logo-tributo.png';
import metamind from 'assets/images/logos/metamind-logo.png';
import goMindHub from 'assets/images/logos/GoMindHub-logo.png';
import stillJobs from 'assets/images/logos/stilljobs-logo.png';
import eCommitment from 'assets/images/logos/ecommitment-logo.avif';
import quix from 'assets/images/logos/quix-logo.png';
import valu from 'assets/images/logos/valuhr-logo.jpeg';

// Incubation
import EiouAcademy from 'assets/images/logos/incubation/Eiou Academy.jpeg';
import TalentSense from 'assets/images/logos/incubation/Talent Sense.jpeg';
import Telly from 'assets/images/logos/incubation/Telly.jpeg';
import UPFC from 'assets/images/logos/incubation/UPFC.jpeg';
import Vecinos from 'assets/images/logos/incubation/Vecinos.jpeg';
import Waste from 'assets/images/logos/incubation/Waste.jpeg';
import Appapacho from 'assets/images/logos/incubation/appapacho.png';
import diamanthum from 'assets/images/logos/incubation/diamanthum.png';
import impactu from 'assets/images/logos/incubation/impactu.png';
import acuerdo from 'assets/images/logos/incubation/logo-acuerdo.webp';
import olistik from 'assets/images/logos/incubation/logo-olistik.webp';
import uFeedback from 'assets/images/logos/incubation/uFeedback.jpeg';
import vonfire from 'assets/images/logos/incubation/vonfire.png';

// Growth
import enliteGrowth from 'assets/images/logos/growth/Logo-enlite.png';
import ophta from 'assets/images/logos/growth/Logo-ophta.png';
import rene from 'assets/images/logos/growth/Logo-rene.png';
import revil from 'assets/images/logos/growth/Logo-revil.png';
import tell from 'assets/images/logos/growth/Logo-Tell.png';

import Button from 'assets/theme/components/button';

function Portfolio() {
  const [arrayIndex, setArrayIndex] = useState(0);

  const logosAcceleration = [
    { image: goMindHub, name: 'GoMind', url: 'https://gomind.cl/' },
    { image: stillJobs, name: 'StillJobs', url: 'https://stilljobs.com/' },
    { image: eCommitment, name: 'eCommitment', url: 'https://www.ecommitment.online/' },
    { image: valu, name: 'Valu', url: 'https://www.linkedin.com/company/valuhr/' },
    { image: quix, name: 'Quix', url: 'https://quix.work/' },
    {
      image: Maia,
      name: 'Maia',
      url: 'https://maia4leaders.splashthat.com/',
    },
    {
      image: Nippy,
      name: 'Nippy',
      url: 'https://nippy.la/',
    },
    {
      image: enlite,
      name: 'Enlite',
      url: 'https://enlite.health/',
    },
    {
      image: Dendro,
      name: 'Dendro',
      url: 'https://dendrolatam.com/',
    },

    {
      image: tributo,
      name: 'Tributo Simple',
      url: 'https://tributosimple.com/',
    },
    {
      image: duo0,
      name: 'Duo0',
      url: 'https://duo0.co/',
    },
    {
      image: akademi,
      name: 'Akademi',
      url: 'https://akademi.com/',
    },
    {
      image: Vgo,
      name: 'V-Go',
      url: 'https://www.vgo.com.ar/',
    },
    {
      image: SomosFit,
      name: 'SomosFit',
      url: 'https://somosfit.com/',
    },
    {
      image: NoPausa,
      name: 'NoPausa',
      url: 'https://no-pausa.com/',
    },
    {
      image: metamind,
      name: 'Metamind',
      url: 'https://metamind.la/',
    },
    {
      image: rezet,
      name: 'Rezet',
      url: 'https://www.rezet.global/',
    },
    {
      image: Aconcagua,
      name: 'Aconcagua',
      url: 'https://aconcagua.finance/',
    },
    {
      image: DrGea,
      name: 'DrGea',
      url: 'https://drgea.com/',
    },
    {
      image: Qxm,
      name: 'Qxm',
      url: 'https://qxm.com.ar/',
    },
    {
      image: fincrick,
      name: 'Fincrick',
      url: 'https://fincrick.com/',
    },
    {
      image: mentalgram,
      name: 'Mentalgram',
      url: 'https://mentalgram.infinixsoft.com/',
    },
    {
      image: busencu,
      name: 'Busencu',
      url: 'https://busencu.com/',
    },
    {
      image: goldu,
      name: 'Goldu',
      url: 'https://glodu.tech/',
    },
    {
      image: dillo,
      name: 'Dillo',
      url: 'https://dillo.ai/',
    },
  ];

  const logosGrowth = [
    {
      image: enlite,
      name: 'Enlite',
      url: 'https://enlite.health/es/ar/',
    },
    {
      image: ophta,
      name: 'Ophta',
      url: 'https://eyehealthvr.com.ar/',
    },
    {
      image: rene,
      name: 'Hola René',
      url: 'https://holarene.com/',
    },
    {
      image: revil,
      name: 'Reliv',
      url: 'https://reliv.la',
    },
    {
      image: tell,
      name: 'Tell',
      url: 'https://tellapp.org/',
    },
  ];

  const logosIncubation = [
    {
      image: acuerdo,
      name: 'Acuerdo Sencillo',
      url: 'https://www.acuerdosencillo.com/',
    },
    {
      image: UPFC,
      name: 'UPFC',
      url: 'https://upfcoin.org/',
    },
    {
      image: Telly,
      name: 'Telly',
      url: 'https://telly.bot/',
    },
    {
      image: Vecinos,
      name: 'Vecinos',
      url: 'https://vecinos.com/',
    },
    {
      image: Waste,
      name: 'Waste',
      url: 'https://waste.com/',
    },
    {
      image: eCommitment,
      name: 'eCommitment',
      url: 'https://www.ecommitment.online/',
    },
    {
      image: EiouAcademy,
      name: 'Eiou Academy',
      url: 'https://eiouacademy.com/',
    },
    {
      image: TalentSense,
      name: 'Talent Sense',
      url: 'https://talentsense.com/',
    },
    {
      image: diamanthum,
      name: 'Diamanthum',
      url: 'https://diamanthum.com/',
    },
    {
      image: uFeedback,
      name: 'uFeedback',
      url: 'https://ufeedback.xyz/',
    },
    {
      image: Appapacho,
      name: 'Appapacho',
      url: 'https://www.appapacho.com.ar/',
    },
    {
      image: impactu,
      name: 'Impactu',
      url: 'https://impactuhabits.com/',
    },
    {
      image: olistik,
      name: 'Olistik',
      url: 'https://olistik.life',
    },
    {
      image: vonfire,
      name: 'Vonfire',
      url: 'https://vonfire.co/',
    },
    // {
    //   image: invitech,
    //   name: 'Invitech',
    //   url: '/Portfolio',
    // },
    // {
    //   image: SizeMe,
    //   name: 'SizeMe AI',
    //   url: 'https://sizemeai.com/',
    // },
    // {
    //   image: Telly,
    //   name: 'Telly',
    //   url: '/Portfolio',
    // },
  ];
  const BoxLogo = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF',
    width: '160px',
    height: '160px',
    boxShadow: '0px 2px 64px rgba(8, 0, 97, 0.08)',
    borderRadius: '10px',
    border: '2px solid #FFF',
    transition: 'all 150ms ease-in',
    '&:hover': {
      border: '2px solid #DB6639',
      boxShadow: '-6px 6px 0px 0px #DB6639',
      marginTop: '-6px',
      marginLeft: '6px',
      marginRight: '-6px',
    },
  };
  const theme = useTheme();

  function changeArray() {
    switch (arrayIndex) {
      case 0:
        return logosAcceleration;
      case 1:
        return logosIncubation;
      case 2:
        return logosGrowth;
      default:
        return logosAcceleration;
    }
  }
  function nextArray() {
    setArrayIndex((prevIndex) => (prevIndex + 1) % 3);
  }

  return (
    <section id="portfolio">
      <MKBox display="flex" py={6} position="relative" id="PortfolioAcademy">
        <CustomContainer>
          <MKBox pt={6} pb={3} textAlign="center" mt={4}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
                <MKTypography variant="flywheel" color="primary">
                  PORTFOLIO
                </MKTypography>
                <MKTypography variant="h1" color="text" mb={1}>
                  <b>Who</b> we worked with
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
          <MKBox display="flex" justifyContent="center" gap={2} mb={3}>
            <MKButton
              variant="contained"
              color={arrayIndex === 1 ? 'primary' : 'white'}
              sx={{
                ...Button,
                borderRadius: '10px',
              }}
              onClick={() => {
                setArrayIndex(1);
              }}
            >
              Incubation
            </MKButton>
            <MKButton
              variant="contained"
              color={arrayIndex === 0 ? 'primary' : 'white'}
              sx={{
                ...Button,
                borderRadius: '10px',
              }}
              onClick={() => {
                setArrayIndex(0);
              }}
            >
              Acceleration
            </MKButton>

            <MKButton
              variant="contained"
              color={arrayIndex === 2 ? 'primary' : 'white'}
              sx={{
                ...Button,
                borderRadius: '10px',
              }}
              onClick={() => {
                setArrayIndex(2);
              }}
            >
              Growth
            </MKButton>
          </MKBox>
          <MKBox
            display="flex"
            flexDirection="row"
            gap="16px"
            justifyContent="center"
            flexWrap="wrap"
            style={{ cursor: 'pointer' }}
          >
            {changeArray().map((item) => (
              <MKBox
                sx={{
                  ...BoxLogo,
                }}
                onClick={() => {
                  window.open(item.url);
                }}
              >
                {item.image ? (
                  <MKBox
                    className="img-card"
                    component="img"
                    src={item.image}
                    alt={item.name}
                    width="85px"
                    position="relative"
                    zIndex={1}
                    top="auto"
                  />
                ) : (
                  <MKTypography variant="h4" color="text" mb={1}>
                    {item.name}
                  </MKTypography>
                )}
              </MKBox>
            ))}
          </MKBox>
          {/* <MKBox pt={6} pb={8} textAlign="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} lg={7} alignItems="center" flexDirection="column">
              <MKTypography variant="body1" color="text">
                Aconcagua provides lenders the latest tech-based collaterals. This new paradigm of
                lending will: 1) increase the base of clients; 2) increase the credit size; 3)
                decrease the interest rate.
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox> */}
        </CustomContainer>
      </MKBox>
    </section>
  );
}

export default Portfolio;
