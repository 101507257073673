/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import createGlobe from 'cobe';
import { keyframes } from '@emotion/react'; // Importamos keyframes para definir animaciones
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

// Componentes personalizados
import MKBox from 'DS/components/MKBox';
import MKTypography from 'DS/components/MKTypography';

// Imágenes de fondo
import subtract from 'assets/images/illustrations/Subtract.svg';
import { Button, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'; // Importa el icono de flecha

import Background from 'assets/images/backgroundHome.png';

function Banner() {
  const canvasRef = useRef();
  const [textLoaded, setTextLoaded] = useState(false); // Estado para el efecto de texto

  // Definir los keyframes de la animación "aurora"
  const auroraAnimation = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  `;

  // Definir los keyframes de la animación de rebote para la flecha
  const bounceAnimation = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  `;

  // Actualizamos el título del documento cuando se carga el componente
  useEffect(() => {
    document.title = 'Koi Ventures';
  }, []);

  // Función de scroll suave hacia el target deseado
  const smoothScroll = (target, duration) => {
    const targetElement = document.getElementById(target);
    if (!targetElement) return;
    const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    let startTime = null;

    const ease = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = ease(timeElapsed, startPosition, targetPosition - startPosition, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  // Efecto "ease-in" para el texto
  useEffect(() => {
    setTimeout(() => {
      setTextLoaded(true); // Marcamos que el texto está listo para la transición
    }, 500); // Retardo antes de la animación del texto
  }, []);

  useEffect(() => {
    let phi = 0;
    let width = 0;
    const onResize = () => canvasRef.current && (width = canvasRef.current.offsetWidth);
    window.addEventListener('resize', onResize);
    onResize();
    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: width * 2,
      height: width * 2,
      phi: -0.6,
      theta: -0.5,
      dark: 0.5,
      diffuse: 3,
      mapSamples: 16000,
      mapBrightness: 10000,
      baseColor: [0.4, 0.365, 0.784],
      markerColor: [0.4, 0.4, 0.8],
      glowColor: [0.4, 0.365, 0.784],
      markers: [
        { location: [4.711, -74.0721], size: 0.05 },
        { location: [-33.4489, -70.6693], size: 0.05 }, // Santiago, Chile
        { location: [-15.8267, -47.9218], size: 0.05 }, // Brasília, Brasil
        { location: [-30.9011, -54.1645], size: 0.05 }, // Montevideo, Uruguay
        { location: [-34.6037, -58.3816], size: 0.05 },
        { location: [-0.1807, -78.4678], size: 0.05 }, // Quito, Ecuador
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        // state.phi = phi;
        phi += 0.005;
        state.width = width * 2;
        state.height = width * 2;
      },
    });
    setTimeout(() => (canvasRef.current.style.opacity = '0.8'));
    return () => {
      globe.destroy();
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <>
      <MKBox
        width="100%"
        sx={{
          display: 'grid',
          placeItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          // backgroundColor: '#575DC8', // Color de fondo (base)
          backgroundImage: `url(${Background})`, // Imagen de fondo]
          backgroundSize: '200% 120%',
          borderRadius: '0 0 60px 60px',
          p: { xs: 2, lg: 5 },
          py: { xs: 0, lg: 17 },
          // backgroundImage: `url(${subtract})`, // Imagen de fondo
          animation: `${auroraAnimation} 10s ease-in-out infinite`,
          height: {
            xs: '90dvh',
            lg: 'auto',
          },
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            px: { xs: 1, lg: 0 },
            justifyContent: 'space-between',
            alignItems: 'center',
            py: { xs: 0, lg: 20 },
          }}
        >
          {/* Sección del texto principal */}
          <Grid
            item
            xs={12}
            lg={5}
            flexDirection="column"
            zIndex={{
              xs: 1,
              lg: 0,
            }}
            sx={{
              opacity: textLoaded ? 1 : 0,
              transform: textLoaded ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 1s ease-in, transform 1s ease-in',
            }}
          >
            <MKTypography
              variant="h1"
              sx={{
                textAlign: {
                  xs: 'center',
                  lg: 'start',
                },
                fontWeight: 'light',
                fontSize: '50px',
                color: '#fff', // Texto en blanco
              }}
            >
              Creating a global network of{' '}
              <span
                style={{
                  fontWeight: 'bold',
                }}
              >
                impact driven entrepreneurs.
              </span>
            </MKTypography>
          </Grid>
          {/* Sección del globo animado */}
          {/* Globo animado */}
          <Grid item xs={12} lg={7}>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: -200,
                width: '1000px',
                height: '1000px',
                zIndex: 0,
                display: {
                  xs: 'none',
                  lg: 'block',
                },
              }}
            >
              <canvas
                ref={canvasRef}
                style={{
                  width: '100%',
                  height: '100%',
                  contain: 'layout paint size',
                  opacity: 0,
                  transition: 'opacity 1s ease',
                }}
              />
            </Box>
          </Grid>
        </Grid>
        {/* Botón que hace scroll hacia la siguiente sección */}
        <Button
          sx={{
            position: 'absolute',
            bottom: 60, // Posición vertical del botón
            left: '50%',
            transform: 'translateX(-50%)',
            height: 'fit-content',
            color: '#fff',
            border: 'none',
            flexDirection: 'column',
            borderRadius: 10,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => {
            smoothScroll('vision', 190); // Scroll hacia la sección "vision"
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontSize: 20,
              cursor: 'pointer',
              textTransform: 'none',
            }}
          >
            Scroll to discover
          </Typography>
        </Button>

        {/* Flecha animada hacia abajo */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 20, // Posición debajo del botón de scroll
            left: '50%',
            transform: 'translateX(-50%)',
            animation: `${bounceAnimation} 2s infinite`, // Aplica la animación de rebote
            cursor: 'pointer',
            '&:hover': {
              color: '#DB6639', // Cambia el color al hacer hover
            },
          }}
          onClick={() => {
            smoothScroll('vision', 190); // Opcional: hacer scroll al hacer clic en la flecha
          }}
        >
          <KeyboardArrowDownIcon
            sx={{ color: '#fff', fontSize: 80, ml: '-10px', cursor: 'pointer' }}
          />
        </Box>
      </MKBox>
    </>
  );
}

export default Banner;
