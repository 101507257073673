// Importar logos de empresas
import enlite from 'assets/images/logos/Enlite (2).png';
import busencu from 'assets/images/logos/busencu-logo.svg';
import dillo from 'assets/images/logos/dillo-logo.png';
import DrGea from 'assets/images/logos/dr-gea.png';
import duo0 from 'assets/images/logos/duo0-logo.png';
import Vgo from 'assets/images/logos/logo-V-Go.png';
import Aconcagua from 'assets/images/logos/logo-aconcagua.png';
import akademi from 'assets/images/logos/logo-akademi.png';
import Dendro from 'assets/images/logos/logo-dendro.png';
import fincrick from 'assets/images/logos/logo-fincrick.png';
import goldu from 'assets/images/logos/logo-glodu.png';
import Maia from 'assets/images/logos/logo-maia.svg';
import mentalgram from 'assets/images/logos/logo-mentalgram.svg';
import Nippy from 'assets/images/logos/logo-nippy.svg';
import NoPausa from 'assets/images/logos/logo-no-pausa.png';
import Qxm from 'assets/images/logos/logo-qxm.png';
import rezet from 'assets/images/logos/logo-rezet.png';
import SomosFit from 'assets/images/logos/logo-somosfit.png';
import tributo from 'assets/images/logos/logo-tributo.png';
import metamind from 'assets/images/logos/metamind-logo.png';
import goMindHub from 'assets/images/logos/GoMindHub-logo.png';
import stillJobs from 'assets/images/logos/stilljobs-logo.png';
import eCommitment from 'assets/images/logos/ecommitment-logo.avif';
import quix from 'assets/images/logos/quix-logo.png';
import valu from 'assets/images/logos/valuhr-logo.jpeg';

// Incubation
import EiouAcademy from 'assets/images/logos/incubation/Eiou Academy.jpeg';
import TalentSense from 'assets/images/logos/incubation/Talent Sense.jpeg';
import Telly from 'assets/images/logos/incubation/Telly.jpeg';
import UPFC from 'assets/images/logos/incubation/UPFC.jpeg';
import Vecinos from 'assets/images/logos/incubation/Vecinos.jpeg';
import Waste from 'assets/images/logos/incubation/Waste.jpeg';
import Appapacho from 'assets/images/logos/incubation/appapacho.png';
import diamanthum from 'assets/images/logos/incubation/diamanthum.png';
import impactu from 'assets/images/logos/incubation/impactu.png';
import acuerdo from 'assets/images/logos/incubation/logo-acuerdo.webp';
import olistik from 'assets/images/logos/incubation/logo-olistik.webp';
import uFeedback from 'assets/images/logos/incubation/uFeedback.jpeg';
import vonfire from 'assets/images/logos/incubation/vonfire.png';

// Growth
import enliteGrowth from 'assets/images/logos/growth/Logo-enlite.png';
import ophta from 'assets/images/logos/growth/Logo-ophta.png';
import rene from 'assets/images/logos/growth/Logo-rene.png';
import revil from 'assets/images/logos/growth/Logo-revil.png';
import tell from 'assets/images/logos/growth/Logo-Tell.png';

import buenosNegocios from 'assets/images/logos/buenos-negocios.png';
import wobox from 'assets/images/logos/wobox-logo.png';

// Estilos de botones personalizados

const categories = ['Aceleración', 'Incubación', 'Growth'];

const logosAcceleration = [
  { image: goMindHub, name: 'GoMind', url: 'https://gomind.cl/' },
  { image: stillJobs, name: 'StillJobs', url: 'https://stilljobs.com/' },
  { image: eCommitment, name: 'eCommitment', url: 'https://www.ecommitment.online/' },
  { image: valu, name: 'Valu', url: 'https://www.linkedin.com/company/valuhr/' },
  { image: quix, name: 'Quix', url: 'https://quix.work/' },
  { image: Maia, name: 'Maia', url: 'https://maia4leaders.splashthat.com/' },
  { image: Nippy, name: 'Nippy', url: 'https://nippy.la/' },
  { image: enlite, name: 'Enlite', url: 'https://enlite.health/' },
  { image: Dendro, name: 'Dendro', url: 'https://dendrolatam.com/' },
  { image: tributo, name: 'Tributo Simple', url: 'https://tributosimple.com/' },
  { image: duo0, name: 'Duo0', url: 'https://duo0.co/' },
  { image: akademi, name: 'Akademi', url: 'https://akademi.com/' },
  { image: Vgo, name: 'V-Go', url: 'https://www.vgo.com.ar/' },
  { image: SomosFit, name: 'SomosFit', url: 'https://somosfit.com/' },
  { image: NoPausa, name: 'NoPausa', url: 'https://no-pausa.com/' },
  { image: metamind, name: 'Metamind', url: 'https://metamind.la/' },
  { image: rezet, name: 'Rezet', url: 'https://www.rezet.global/' },
  { image: Aconcagua, name: 'Aconcagua', url: 'https://aconcagua.finance/' },
  { image: DrGea, name: 'DrGea', url: 'https://drgea.com/' },
  { image: Qxm, name: 'Qxm', url: 'https://qxm.com.ar/' },
  { image: fincrick, name: 'Fincrick', url: 'https://fincrick.com/' },
  { image: mentalgram, name: 'Mentalgram', url: 'https://mentalgram.infinixsoft.com/' },
  { image: busencu, name: 'Busencu', url: 'https://busencu.com/' },
  { image: goldu, name: 'Goldu', url: 'https://glodu.tech/' },
  { image: dillo, name: 'Dillo', url: 'https://dillo.ai/' },
];

const logosGrowth = [
  { image: enliteGrowth, name: 'Enlite', url: 'https://enlite.health/es/ar/' },
  { image: ophta, name: 'Ophta', url: 'https://eyehealthvr.com.ar/' },
  { image: rene, name: 'Hola René', url: 'https://holarene.com/' },
  { image: revil, name: 'Reliv', url: 'https://reliv.la' },
  { image: tell, name: 'Tell', url: 'https://tellapp.org/' },
];

const logosIncubation = [
  { image: acuerdo, name: 'Acuerdo Sencillo', url: 'https://www.acuerdosencillo.com/' },
  { image: UPFC, name: 'UPFC', url: 'https://upfcoin.org/' },
  { image: Telly, name: 'Telly', url: 'https://telly.bot/' },
  { image: Vecinos, name: 'Vecinos', url: 'https://vecinos.com/' },
  { image: Waste, name: 'Waste', url: 'https://waste.com/' },
  { image: EiouAcademy, name: 'Eiou Academy', url: 'https://eiouacademy.com/' },
  { image: TalentSense, name: 'Talent Sense', url: 'https://talentsense.com/' },
  { image: diamanthum, name: 'Diamanthum', url: 'https://diamanthum.com/' },
  { image: uFeedback, name: 'uFeedback', url: 'https://ufeedback.xyz/' },
  { image: Appapacho, name: 'Appapacho', url: 'https://www.appapacho.com.ar/' },
  { image: impactu, name: 'Impactu', url: 'https://impactuhabits.com/' },
  { image: olistik, name: 'Olistik', url: 'https://olistik.life' },
  { image: vonfire, name: 'Vonfire', url: 'https://vonfire.co/' },
];

const logosTech = [
  { image: eCommitment, name: 'eCommitment', url: 'https://www.ecommitment.online/' },
  { image: enliteGrowth, name: 'Enlite', url: 'https://enlite.health/es/ar/' },
  { image: rene, name: 'Hola René', url: 'https://holarene.com/' },
  { image: Aconcagua, name: 'Aconcagua', url: 'https://aconcagua.finance/' },
  { image: buenosNegocios, name: 'Buenos Negocios', url: 'https://buenosnegocios.ar/' },
  { image: wobox, name: 'Wobox', url: 'https://wobox.ar/landing' },
];

export { logosAcceleration, logosGrowth, logosIncubation, logosTech };
